import { loadHome } from '../pages/home';
import { loadPortfolio } from '../pages/portfolio';
import { loadContact } from '../pages/contact';
import LazyLoader from '../scripts/lazyLoader';
import { initializeFAQ } from '../scripts/openFaq';

type EventListenerRemoval = () => void;
const eventListeners: { [key: string]: EventListenerRemoval } = {};

function addEventListener(selector: string, event: string, handler: (e: Event) => void) {
  const elements = document.querySelectorAll(selector);
  elements.forEach(element => {
    element.addEventListener(event, handler);
    eventListeners[selector + event] = () => element.removeEventListener(event, handler);
  });
}

function removeEventListeners() {
  Object.keys(eventListeners).forEach((key) => {
    eventListeners[key]();
    delete eventListeners[key];
  });
}

function scrollToSection(sectionId: string) {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    history.replaceState({}, '', `/#${sectionId}`);
  }
}

function handleNavigation(path: string, sectionId?: string) {
  const isSamePath = location.pathname === path;

  if (!isSamePath) {
    history.pushState({}, '', path);
    loadPage(path, sectionId);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  } else if (sectionId) {
    scrollToSection(sectionId);
  } else {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}

function loadPage(path: string, sectionId?: string) {
  if (path === '/') {
    loadHome().then(() => {
      new LazyLoader('.lazy-load');
      initializeFAQ();

      if (sectionId) {
        scrollToSection(sectionId);
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    });
  } else if (path === '/portofoliu') {
    loadPortfolio();
  } else if (path === '/contact') {
    loadContact();
  }
}

function setupNavigationLinks(routes: { selector: string, path: string, sectionId?: string }[]) {
  routes.forEach(({ selector, path, sectionId }) => {
    addEventListener(selector, 'click', (e) => {
      e.preventDefault();
      handleNavigation(path, sectionId);
    });
  });
}

export function setupHeaderNavigation() {
  const headerRoutes = [
    { selector: '#home-link', path: '/', sectionId: '' },
    { selector: '#whyapp-link', path: '/', sectionId: 'apps' },
    { selector: '#services-link', path: '/', sectionId: 'services' },
    { selector: '#faq-link', path: '/', sectionId: 'faq' },
    { selector: '#portfolio-link', path: '/portofoliu' },
    { selector: '#contact-link', path: '/contact' }
  ];
  setupNavigationLinks(headerRoutes);
}

export function setupFooterNavigation() {
  const footerRoutes = [
    { selector: '#footer-home-link', path: '/', sectionId: '' },
    { selector: '#footer-whyapp-link', path: '/', sectionId: 'apps' },
    { selector: '#footer-services-link', path: '/', sectionId: 'services' },
    { selector: '#footer-faq-link', path: '/', sectionId: 'faq' },
    { selector: '#footer-portfolio-link', path: '/portofoliu' },
    { selector: '#footer-contact-link', path: '/contact' }
  ];
  setupNavigationLinks(footerRoutes);
}

export function setupRouter() {
  removeEventListeners();

  setupHeaderNavigation();
  setupFooterNavigation();

  window.addEventListener('popstate', () => {
    const path = location.pathname;
    const sectionId = location.hash.substring(1);
    loadPage(path, sectionId);
  });
}
