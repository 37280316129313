export function initializeFAQ(): void {
  const faqItems = document.querySelectorAll<HTMLElement>(".faq-item");

  faqItems.forEach((item) => {
    const summary = item.querySelector<HTMLElement>(".faq-summary");
    if (summary) {
      summary.addEventListener("click", (event: MouseEvent) => {
        event.preventDefault();
        const isActive = item.classList.contains("active");

        // Close all other faq items
        faqItems.forEach((faq) => {
          faq.classList.remove("active");
        });

        // Toggle the clicked item
        if (!isActive) {
          item.classList.add("active");
        }
      });

      // Add touch event for mobile
      summary.addEventListener("touchstart", (event: TouchEvent) => {
        event.preventDefault();
        const isActive = item.classList.contains("active");

        // Close all other faq items
        faqItems.forEach((faq) => {
          faq.classList.remove("active");
        });

        // Toggle the clicked item
        if (!isActive) {
          item.classList.add("active");
        }
      });
    }
  });
}
