import { loadPageCSS, removePageCSS } from "../components/cssManager";
import projects from "../data/projects.json";

export async function loadPortfolio() {
  const content = document.getElementById("content");
  if (content) {
    // removePageCSS();
    // loadPageCSS("/styles/portfolio.css");
    content.innerHTML = `
      <section class="portfolio-section">
        <h1 class="portfolio-title">Proiecte Recente</h1>
        <div id="portfolio-grid" class="grid-container"></div>
      </section>
    `;

    const gridContainer = document.getElementById("portfolio-grid");
    if (gridContainer) {
      projects.forEach((project: any) => {
        const card = document.createElement("div");
        card.className = "card";
        const img = project?.link ? `<img 
            src="${project.image}" 
            alt="${project.name}"
            class="portfolio-image cursor-pointer"

            onclick="window.open('${project?.link}','_blank')"
            >` : `<img 
            src="${project.image}" 
            alt="${project.name}"
            class="portfolio-image"
            >`

        card.innerHTML = ` 
          ${img}
          <div class="card-content">
            <h3>${project.name}</h3>
            <div class="tags">
              ${project.tags
                .map((tag: string) => `<span class="tag">${tag}</span>`)
                .join("")}
            </div>
          </div>
        `;
        gridContainer.appendChild(card);
      });
    } else {
      console.error("Portfolio grid container not found");
    }
  } else {
    console.error("Content element not found");
  }
}
