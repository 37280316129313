import { loadPageCSS, removePageCSS } from "../components/cssManager";

export async function loadHome() {
  const content = document.getElementById("content");
  if (content) {
    // removePageCSS();
    // loadPageCSS("/styles/home.css");
    content.innerHTML = `
    <section id="hero">
    <div class="hero-content">
      <h1 class="first-title">Servicii dezvoltare software custom</h1>
      <h2 class="second-title">Oferim <span>soluții personalizate</span> pentru afacerea ta.</h2>
      <h3 class="third-title">O aplicație software este o investiție care aduce beneficii pe termen lung pentru afacerea ta.</h3>
      <button class="cta" onclick="location.href='/contact'">Cere o oferta</button>
    </div>
  </section>

    <section id="whyapp">
        <div>
          <h2 class="whyapp-title">De ce am nevoie de o aplicație software personalizată?</h2>
        </div>
        <div class="whyapp-list-grid">
          <div class="whyapp-list-item">
            <div class="whyapp-list-number">01</div>
            <div class="whyapp-list-info">
              <h3 class="whyapp-list-title">Eficiență operațională:</h3>
              <p>
                O aplicație software personalizată automatizează procesele și
                optimizează resursele, reducând costurile operaționale și
                crescând productivitatea.
              </p>
            </div>
          </div>
          <div class="whyapp-list-item">
            <div class="whyapp-list-number">02</div>
            <div class="whyapp-list-info">
              <h3 class="whyapp-list-title">Adaptabilitate și personalizare:</h3>
              <p>
                Soluțiile software personalizate sunt create specific pentru nevoile afacerii tale, oferind funcționalități care răspund exact cerințelor tale.
              </p>
            </div>
          </div>
          <div class="whyapp-list-item">
            <div class="whyapp-list-number">03</div>
            <div class="whyapp-list-info">
              <h3 class="whyapp-list-title">Scalabilitate și flexibilitate:</h3>
              <p>
                Aplicațiile software personalizate sunt proiectate pentru a crește odată cu afacerea ta, permițând adăugarea de noi funcționalități și adaptarea la schimbările de pe piață.
              </p>
            </div>
          </div>
          <div class="whyapp-list-item">
            <div class="whyapp-list-number">04</div>
            <div class="whyapp-list-info">
              <h3 class="whyapp-list-title">Accesibilitate și colaborare îmbunătățite:</h3>
              <p>
                Soluțiile software personalizate pot fi accesibile de pe orice dispozitiv și locație, facilitând munca la distanță și colaborarea eficientă între echipe.
              </p>
            </div>
          </div>
          <div class="whyapp-list-item">
            <div class="whyapp-list-number">05</div>
            <div class="whyapp-list-info">
              <h3 class="whyapp-list-title">Securitate și conformitate:</h3>
              <p>
                O aplicație software personalizată asigură un nivel ridicat de securitate și respectă reglementările și standardele specifice industriei tale, protejând datele sensibile și confidențiale.
              </p>
            </div>
          </div>
        </div>
      </section>


      <section id="apps">
        <div class="project-container" onclick="window.open('https://www.youtube.com/watch?v=eWzNS9B6Mi0&t=20s&ab_channel=pddle','_blank')">
          <div class="app-left-container">
            <img
              data-src="/assets/images/pddle.svg"
              class="app-logo lazy-load"
              alt="pddle logo"
              draggable="false"
            />
          </div>
          <div class="app-center-container">
            <img
                data-src="/assets/images/pddleimage.webp"
                class="app-image lazy-load"
                alt="pddle presentation image"
                draggable="false"
              />
             
          </div>
          <div class="app-right-container">
            <div class="app-info-row">
              <img
                data-src="/assets/icons/mob.svg"
                class="app-info-image lazy-load"
                alt="mobile app logo"
                draggable="false"
              />
              <h4 class="app-info">Mobile</h4>
            </div>
            <div class="app-info-row">
              <img
                data-src="/assets/icons/web.svg"
                class="app-info-image lazy-load"
                alt="web app logo"
                draggable="false"
              />
              <h4 class="app-info">Web</h4>
            </div>
            <div class="app-info-row">
              <img
                data-src="/assets/icons/hardware.svg"
                class="app-info-image lazy-load"
                alt="hardware logo"
                draggable="false"
              />
              <h4 class="app-info">Hardware</h4>
            </div>
          </div>
        </div>
        <div class="project-container">
          <div class="app-left-container">
            <img
              data-src="/assets/images/goscootLogo.svg"
              class="app-logo lazy-load"
              alt="goScoot logo"
              draggable="false"
            />
          </div>
          <div class="app-center-container">
            <img
                data-src="/assets/images/goscootImage.webp"
                class="app-image lazy-load"
                alt="goScoot presentation image"
                draggable="false"
              />
          </div>
          <div class="app-right-container">
            <div class="app-info-row">
              <img
                data-src="/assets/icons/mob.svg"
                class="app-info-image lazy-load"
                alt="mobile app logo"
                draggable="false"
              />
              <h4 class="app-info">Mobile</h4>
            </div>
            <div class="app-info-row">
              <img
                data-src="/assets/icons/web.svg"
                class="app-info-image lazy-load"
                alt="web app logo"
                draggable="false"
              />
              <h4 class="app-info">Web</h4>
            </div>
          </div>
        </div>
        <div class="project-container">
          <div class="app-left-container">
            <img
              data-src="/assets/images/rumzLogo.svg"
              class="app-logo lazy-load"
              alt="rumz logo"
              draggable="false"
            />
          </div>
          <div class="app-center-container">
            <img
                data-src="/assets/images/rumzImage.webp"
                class="app-image lazy-load"
                alt="rumz presentation image"
                draggable="false"
              />
          </div>
          <div class="app-right-container">
            <div class="app-info-row">
              <img
                data-src="/assets/icons/mob.svg"
                class="app-info-image lazy-load"
                alt="mobile app logo"
                draggable="false"
              />
              <h4 class="app-info">Mobile</h4>
            </div>
          </div>
        </div>
        <div class="project-container">
          <div class="app-left-container">
            <img
              data-src="/assets/images/Losty.svg"
              class="app-logo lazy-load"
              alt="Losty logo"
              draggable="false"
            />
          </div>
          <div class="app-center-container">
            <img
                data-src="/assets/images/lostyImage.webp"
                class="app-image lazy-load"
                alt="Lost Tag Image"
                draggable="false"
              />
          </div>
          <div class="app-right-container">
            <div class="app-info-row">
              <img
                data-src="/assets/icons/web.svg"
                class="app-info-image lazy-load"
                alt="web-icon"
                draggable="false"
              />
              <h4 class="app-info">Web</h4>
            </div>
          </div>
        </div>
      </section>
    
    <section id="services">
        <div class="services-info-container">
          <h2 class="service-info-title">Ce servicii oferim?</h2>
          <p class="service-info-description">
            Alege serviciul potrivit pentru business-ul tău, iar noi îți vom
            face o ofertă.
          </p>
          <button class="cta" onclick="location.href='/contact'">Cere o oferta</button>
        </div>
        <div class="services-list-grid">
          <div class="service-list-item">
            <img
              data-src="/assets/icons/consultanta.svg"
              class="service-image lazy-load"
              alt="consultanta IT"
              draggable="false"
            />
            <h3 class="service-list-title">Consultanta IT</h3>
            <div class="service-list-description">
              <div class="service-list-row">
                <img
                  data-src="/assets/images/checkpoint.svg"
                  class="check-point lazy-load"
                  alt="TypeScript logo"
                  draggable="false"
                />
                <p class="service-list-point">
                  Îți oferim cele mai bune sfaturi pentru a-ți putea eficientiza
                  procesele din business
                </p>
              </div>
              <div class="service-list-row">
                <img
                  data-src="/assets/images/checkpoint.svg"
                  class="check-point lazy-load"
                  alt="TypeScript logo"
                  draggable="false"
                />
                <p class="service-list-point">
                  Analizăm nevoile IT ale afacerii tale
                </p>
              </div>
              <div class="service-list-row">
                <img
                  data-src="/assets/images/checkpoint.svg"
                  class="check-point lazy-load"
                  alt="TypeScript logo"
                  draggable="false"
                />
                <p class="service-list-point">
                  Propunem soluții personalizate
                </p>
              </div>
              <div class="service-list-row">
                <img
                  data-src="/assets/images/checkpoint.svg"
                  class="check-point lazy-load"
                  alt="TypeScript logo"
                  draggable="false"
                />
                <p class="service-list-point">
                  Asistență în implementarea soluțiilor
                </p>
              </div>
            </div>
          </div>
          <div class="service-list-item">
            <img
              data-src="/assets/icons/mob.svg"
              class="service-image lazy-load"
              alt="mobile app logo"
              draggable="false"
            />
            <h3 class="service-list-title">Aplicații mobile</h3>
            <div class="service-list-description">
              <div class="service-list-row">
                <img
                  data-src="/assets/images/checkpoint.svg"
                  class="check-point lazy-load"
                  alt="TypeScript logo"
                  draggable="false"
                />
                <p class="service-list-point">
                  Concepem pentru tine aplicații mobile pentru Android și iOS
                </p>
              </div>
              <div class="service-list-row">
                <img
                  data-src="/assets/images/checkpoint.svg"
                  class="check-point lazy-load"
                  alt="TypeScript logo"
                  draggable="false"
                />
                <p class="service-list-point">
                  Dezvoltăm interfețe intuitive și prietenoase
                </p>
              </div>
              <div class="service-list-row">
                <img
                  data-src="/assets/images/checkpoint.svg"
                  class="check-point lazy-load"
                  alt="TypeScript logo"
                  draggable="false"
                />
                <p class="service-list-point">
                  Integrare cu servicii backend
                </p>
              </div>
              <div class="service-list-row">
                <img
                  data-src="/assets/images/checkpoint.svg"
                  class="check-point lazy-load"
                  alt="TypeScript logo"
                  draggable="false"
                />
                <p class="service-list-point">
                  Optimizare pentru performanță și securitate
                </p>
              </div>
            </div>
          </div>
          <div class="service-list-item">
            <img
              data-src="/assets/icons/web.svg"
              class="service-image lazy-load"
              alt="web app logo"
              draggable="false"
            />
            <h3 class="service-list-title">Aplicații web</h3>
            <div class="service-list-description">
              <div class="service-list-row">
                <img
                  data-src="/assets/images/checkpoint.svg"
                  class="check-point lazy-load"
                  alt="TypeScript logo"
                  draggable="false"
                />
                <p class="service-list-point">
                  Construim aplicații web adaptabile și scalabile
                </p>
              </div>
              <div class="service-list-row">
                <img
                  data-src="/assets/images/checkpoint.svg"
                  class="check-point lazy-load"
                  alt="TypeScript logo"
                  draggable="false"
                />
                <p class="service-list-point">
                  Design modern și funcțional
                </p>
              </div>
              <div class="service-list-row">
                <img
                  data-src="/assets/images/checkpoint.svg"
                  class="check-point lazy-load"
                  alt="TypeScript logo"
                  draggable="false"
                />
                <p class="service-list-point">
                  Integrare cu diverse API-uri și servicii
                </p>
              </div>
              <div class="service-list-row">
                <img
                  data-src="/assets/images/checkpoint.svg"
                  class="check-point lazy-load"
                  alt="TypeScript logo"
                  draggable="false"
                />
                <p class="service-list-point">
                  Asigurăm mentenanță și actualizări periodice
                </p>
              </div>
            </div>
          </div>
          <div class="service-list-item">
            <img
              data-src="/assets/icons/software.svg"
              class="service-image lazy-load"
              alt="software automation logo"
              draggable="false"
            />
            <h3 class="service-list-title">Automatizari software</h3>
            <div class="service-list-description">
              <div class="service-list-row">
                <img
                  data-src="/assets/images/checkpoint.svg"
                  class="check-point lazy-load"
                  alt="TypeScript logo"
                  draggable="false"
                />
                <p class="service-list-point">
                  Automatizăm procesele repetitive pentru eficiență crescută
                </p>
              </div>
              <div class="service-list-row">
                <img
                  data-src="/assets/images/checkpoint.svg"
                  class="check-point lazy-load"
                  alt="TypeScript logo"
                  draggable="false"
                />
                <p class="service-list-point">
                  Creăm scripturi și aplicații pentru automatizare
                </p>
              </div>
              <div class="service-list-row">
                <img
                  data-src="/assets/images/checkpoint.svg"
                  class="check-point lazy-load"
                  alt="TypeScript logo"
                  draggable="false"
                />
                <p class="service-list-point">
                  Integrare cu sisteme existente
                </p>
              </div>
              <div class="service-list-row">
                <img
                  data-src="/assets/images/checkpoint.svg"
                  class="check-point lazy-load"
                  alt="TypeScript logo"
                  draggable="false"
                />
                <p class="service-list-point">
                  Suport tehnic continuu
                </p>
              </div>
            </div>
          </div>
          <div class="service-list-item">
            <img
              data-src="/assets/icons/hardware.svg"
              class="service-image lazy-load"
              alt="TypeScript logo"
              draggable="false"
            />
            <h3 class="service-list-title">Automatizari hardware</h3>
            <div class="service-list-description">
              <div class="service-list-row">
                <img
                  data-src="/assets/images/checkpoint.svg"
                  class="check-point lazy-load"
                  alt="TypeScript logo"
                  draggable="false"
                />
                <p class="service-list-point">
                  Proiectăm soluții hardware pentru automatizare industrială
                </p>
              </div>
              <div class="service-list-row">
                <img
                  data-src="/assets/images/checkpoint.svg"
                  class="check-point lazy-load"
                  alt="TypeScript logo"
                  draggable="false"
                />
                <p class="service-list-point">
                  Integrare cu software dedicat
                </p>
              </div>
              <div class="service-list-row">
                <img
                  data-src="/assets/images/checkpoint.svg"
                  class="check-point lazy-load"
                  alt="TypeScript logo"
                  draggable="false"
                />
                <p class="service-list-point">
                  Monitorizare și control la distanță
                </p>
              </div>
              <div class="service-list-row">
                <img
                  data-src="/assets/images/checkpoint.svg"
                  class="check-point lazy-load"
                  alt="TypeScript logo"
                  draggable="false"
                />
                <p class="service-list-point">
                  Testare și optimizare a soluțiilor implementate
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

  <section id="faq" class="faq-container">
    <div class="faq-header">
      <h2>Întrebări Frecvente</h2>
      <img 
         data-src="/assets/images/question.webp" 
         class="lazy-load"
         alt="FAQ Image"
         draggable="false"
      >
    </div>
    <div class="faq-list">
      <div class="faq-item">
        <div class="faq-summary">
          Cât durează dezvoltarea unei aplicații software personalizate?
        </div>
        <div class="faq-content">
          <p>Durata dezvoltării depinde de complexitatea proiectului și de cerințele specifice. Un proiect simplu poate dura câteva săptămâni, în timp ce unul complex poate necesita câteva luni.</p>
        </div>
      </div>

      <div class="faq-item">
        <div class="faq-summary">
          Ce tipuri de aplicații dezvoltați?
        </div>
        <div class="faq-content">
          <p>Dezvoltăm aplicații mobile pentru Android și iOS, aplicații web, și soluții software personalizate. Acestea includ aplicații care:</p>
          <ul>
            <li>includ hărți interactive</li>
            <li>introduc procesatoare de plăți</li>
            <li>folosesc validatoare KYC (Know Your Customer)</li>
            <li>afișează date în timp real</li>
            <li>interacționează cu componente hardware</li>
            <li>interacționează cu alte sisteme software</li>
          </ul>
        </div>
      </div>
      <div class="faq-item">
        <div class="faq-summary">
          Care este procesul de dezvoltare software?
        </div>
        <div class="faq-content">
          <p>Procesul include analiza cerințelor, planificarea, design-ul, dezvoltarea, testarea și implementarea. Oferim și mentenanță post-lansare.</p>
        </div>
      </div>
      <div class="faq-item">
        <div class="faq-summary">
          Cum sunt gestionate actualizările și mentenanța?
        </div>
        <div class="faq-content">
          <p>Oferim servicii de actualizare și mentenanță pentru a asigura funcționarea optimă și securitatea aplicațiilor pe termen lung.</p>
        </div>
      </div>
      <div class="faq-item">
        <div class="faq-summary">
          Ce costuri sunt implicate în dezvoltarea unei aplicații?
        </div>
        <div class="faq-content">
          <p>Costurile variază în funcție de complexitatea și funcționalitățile necesare. Oferim estimări detaliate după analiza cerințelor proiectului.</p>
        </div>
      </div>
    </div>
  </section>
    `;
  } else {
    console.error("Content element not found");
  }
}

