import { loadPageCSS, removePageCSS } from "../components/cssManager";

export async function loadContact() {
  const content = document.getElementById("content");
  if (content) {
    // removePageCSS();
    // loadPageCSS("/styles/contact.css");
    content.innerHTML = `
    <section class="contact-section">
      <div class="contact-description-wrapper">
        <p class="contact-description">
          Vrei o aplicație pentru business-ul tău?
        </p>
        <h2 class="contact-title">Contactează-ne</h2>
        <p class="contact-description">
          Completează formularul alegând serviciul dorit, iar noi te vom contacta in cel mai scurt timp.
        </p>
      </div>
      <div class="glass">
        <form class="contact-form" id="contactForm">
          <label for="name">Nume:</label>
          <input type="text" name="name" required />
          <label for="email">Email:</label>
          <input type="email" name="email" required />
          <label for="service">Selecteaza serviciul</label>
          <select name="service" required>
            <option value="" disabled selected>Selecteaza serviciul</option>
            <option value="ConsultantaIT">Consultanța IT</option>
            <option value="AplicatiiMobile">Aplicații Mobile</option>
            <option value="AplicatiiWeb">Aplicații Web</option>
            <option value="AutomatizariSoftware">Automatizari Software</option>
            <option value="AutomatizariHardware">Automatizari Hardware</option>
          </select>
          <label for="message">Lasa-ne un mesaj</label>
          <textarea name="message" required></textarea>
          <div class="button-container">
          <button type="submit" class="cta">Trimite</button>
          </div>
        </form>
      </div>
    </section>
    `;
  }
}
