import { loadHeader } from './components/header';
import { loadFooter } from './components/footer';
import { setupRouter } from './components/router';
import LazyLoader from './scripts/lazyLoader';
import { initializeFAQ } from './scripts/openFaq';

loadHeader();
loadFooter();
setupRouter();

const path = location.pathname;

if (path === '/portofoliu') {
  import('./pages/portfolio').then((module) => module.loadPortfolio());
} else if (path === '/contact') {
  import('./pages/contact').then((module) => module.loadContact());
} else {
  import('./pages/home').then((module) => module.loadHome()).then(() => {
    new LazyLoader('.lazy-load');
    initializeFAQ();

    const sectionId = location.hash.substring(1);
    if (sectionId) {
      document.getElementById(sectionId)?.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  });
}





