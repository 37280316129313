export default class LazyLoader {
    private images: NodeListOf<HTMLImageElement>;
    private imagesLoaded: Set<HTMLImageElement>;

    constructor(selector: string) {
        this.images = document.querySelectorAll<HTMLImageElement>(selector);
        this.imagesLoaded = new Set();
        this.registerEventListeners();
        this.loadImages(); // Initial check in case images should be loaded right away
    }

    private registerEventListeners(): void {
        window.addEventListener('scroll', this.loadImagesBound);
        window.addEventListener('resize', this.loadImagesBound);
        window.addEventListener('orientationChange', this.loadImagesBound);
    }

    private unregisterEventListeners(): void {
        window.removeEventListener('scroll', this.loadImagesBound);
        window.removeEventListener('resize', this.loadImagesBound);
        window.removeEventListener('orientationChange', this.loadImagesBound);
    }

    private loadImagesBound = (): void => {
        this.loadImages();
    };

    private loadImages(): void {
        const viewportHeight = window.innerHeight;
        const imageArray = Array.from(this.images);

        imageArray.forEach(image => {
            if (!this.imagesLoaded.has(image)) {
                const rect = image.getBoundingClientRect();
                if (rect.top >= 0 && rect.top <= viewportHeight) {
                    const src = image.getAttribute('data-src');
                    if (src) {
                        image.src = src;
                        image.classList.remove('lazy-load'); // Optional: remove class after load
                        image.removeAttribute('data-src'); // Remove attribute after loading
                        this.imagesLoaded.add(image);
                    }
                }
            }
        });

        // Check if all images are loaded
        if (this.imagesLoaded.size + 1 === this.images.length) {
            this.unregisterEventListeners();
        }
    }
}
