export function loadHeader() {
  const header = document.getElementById("header");
  if (header) {
    header.innerHTML = `
      <header>
        <nav>
          <div class="logo" href="#home" id="home-link">
            <img src="/assets/images/cccLogo.svg" alt="CleanCodeCulture Logo" width="80" height="80" href="#home">
          </div>
          <ul class="nav-links">
            <li><a href="#home" id="home-link">Acasă</a></li>
            <li><a href="#apps" id="whyapp-link">Proiecte</a></li>
            <li><a href="#services" id="services-link">Servicii</a></li>
            <li><a href="#faq" id="faq-link">Întrebări Frecvente</a></li>
            <li><a href="/portofoliu" id="portfolio-link">Portofoliu</a></li>
            <li><a href="/contact" id="contact-link">Contact</a></li>
          </ul>
          <div class="contact-image-container" onclick="location.href='/contact'">
            <img
              src="/assets/images/contact.svg"
              class="contact-image"
              alt="contact image"
            />
          </div>
          <div class="hamburger" id="hamburger">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </nav>
      </header>
      `;

    const hamburger = document.getElementById("hamburger");
    const navLinks = document.querySelector(".nav-links") as HTMLElement;

    hamburger?.addEventListener("click", () => {
      navLinks.classList.toggle("open");
      hamburger.classList.toggle("toggle");
    });

    const links = document.querySelectorAll(".nav-links a");

    links.forEach(link => {
      link.addEventListener("click", () => {
        navLinks.classList.remove("open");
        hamburger?.classList.remove("toggle");
      });
    });

    document.addEventListener("click", (event) => {
      if (
        !navLinks.contains(event.target as Node) &&
        !hamburger?.contains(event.target as Node)
      ) {
        navLinks.classList.remove("open");
        hamburger?.classList.remove("toggle");
      }
    });
  }
}