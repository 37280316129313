export function loadFooter() {
    const footer = document.getElementById('footer');
    if (footer) {
      footer.innerHTML = `
        <footer class="minimal-footer">
          <div class="footer-grid">
            <div class="footer-box">
              <img
                class="csLogoFooter lazy-load"
                src="/assets/images/cccLogo.svg"
                width="200"
                height="105"
                alt="footerLogo"
                draggable="false"
                />
            </div>
            <div class="footer-box">
              <div class="footer-title">Navigare Rapida</div>
              <ul class="footer-section-list">
                <li class="cursor-pointer" href="#home" id="footer-home-link">Acasă</li>
                <li class="cursor-pointer" href="#whyapp" id="footer-whyapp-link">Proiecte</li>
                <li class="cursor-pointer" href="#services" id="footer-services-link">Servicii</li>
                <li class="cursor-pointer" href="#faq" id="footer-faq-link">Întrebări Frecvente</li>
                <li class="cursor-pointer" href="/portofoliu" id="footer-portfolio-link">Portofoliu</li>
                <li class="cursor-pointer" href="/contact" id="footer-contact-link">Contact</li>
              </ul>
            </div>   
            <div class="footer-box">
              <div class="footer-title">Date Contact</div>
                <ul class="footer-section-list">
                  <li class="list-text-mail">
                    <a href="mailto:andrei.pata@cleanCodeCulture.com">andrei.pata@cleanCodeCulture.com</a>
                  </li>
                  <li class="list-text-mail">
                    <a href="tel:+40726838863">+40 726 838 863</a>
                  </li>
                </ul>

              <div class="footer-title margin-date">Date Firma</div>
              <ul class="footer-section-list">
                <li class="list-text">CLEAN CODE CULTURE SRL</li>
                <li class="list-text">CIF: 47847495</li>
              </ul>
            </div>
            <div class="footer-box">
              <img
                  src="/assets/images/anpc1.webp"
                  class="footer-image"
                  alt="anpc image 1"
                  onclick="window.open('https://anpc.ro/ce-este-sal/','_blank')"
                />
                <img
                  src="/assets/images/anpc2.webp"
                  class="footer-image"
                  alt="anpc image 2"
                  onclick="window.open('https://commission.europa.eu/index_en','_blank')"
                />
            </div>
          </div>
          <p class="copyright">© 2024 CleanCodeCulture. All rights reserved.</p>
        </footer>
      `;
    }
  }
  